import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,Stycke,ContentWrapper} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {XLogOff, Pageheight} from "../../index";

import React, { useState ,useEffect} from 'react';


export default function Login() {

  const [resultText, setResultText] = useState(":)");
  const [resultVariant, setResultVariant] = useState("success");
  const [resultVisible, setResultVisible] = useState(false);
  const [spinnerHidden, setSpinnerHidden] = useState(true);  

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: (window.innerHeight-500)
  });

  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: (window.innerHeight-500)
    });
  }
  useEffect(() => (window.onresize = updateSize), []);  
  useEffect(() => (window.onscroll = updateSize), []);  

  function handleSubmit(e) {
    e.preventDefault();

    setSpinnerHidden(false);
    setResultVisible(true);

    const formData = new FormData(e.target),
    formDataObj = Object.fromEntries(formData.entries())

    var PostData = {SystemID:"E", AuthData : formDataObj};
    
    // https://www.qmenow.com/backend/forms/contact
    fetch('/backend/auth/password', {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json'
        },

        body: JSON.stringify(PostData),
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException==false) {
            setResultVariant("success");      
            setResultText(data.resultText);        
            setSpinnerHidden(true);
            setResultVisible(true);
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('isLoggedIn', true);
            //return <Redirect to='/mypage' />

            setTimeout(function() {
              window.location.href = "/mypage";
            }, 1400);
            
            //this.props.history.push('/mypage');
        } else {
            setResultVariant("warning");
            setResultText(data.resultText);
            setSpinnerHidden(true);
            setResultVisible(true);
        }
     })
     .catch(error => {
        setResultVariant("warning");
        setResultText("Ett fel uppstod!");
        setSpinnerHidden(true);
        setResultVisible(true);
      });
    }

  return (
    <ContentWrapper>
    <Container>
        <Sida style={{minHeight:Pageheight(400)}}>
        <Rubrik>Login</Rubrik>
          <Form onSubmit={handleSubmit}>

              <Form.Group className="mb-3">
                  <Form.Label className="FormHeaderText">Email</Form.Label>
                  <Form.Control autoFocus type="email" name="username" />
              </Form.Group>

              <Form.Group className="mb-3">
                  <Form.Label className="FormHeaderText">Password</Form.Label>
                  <Form.Control type="password" name="password" />
              </Form.Group>

              <Form.Group className="mb-3"  hidden={!resultVisible}>
                  <Alert show={resultVisible} variant={resultVariant}  className="FormSpace_">{resultText}</Alert> 
              </Form.Group>  

              <Form.Group className="mb-3"  hidden={spinnerHidden}>
                  <SpinnerX  />
              </Form.Group> 

              <Form.Group className="mb-3">
                  <Button className="QmeButton" variant="primary" type="submit">Login</Button>
              </Form.Group>         

              <Form.Group className="mb-3">
              <a href="/password/request/new" className="text-decoration-none"  style={{color:'#000', fontSize:'16px'}}>Glömt lösenord</a>
              </Form.Group>                       

          </Form>
                
      </Sida>
      </Container>
      </ContentWrapper>
  );
}