import { Container, Button,Form, Alert} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper} from "../ContentStyles";
import SpinnerX from '../SpinnerX';

import {Pageheight} from "../../index";

import React from 'react';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false
        }
    }

    CheckAccessToken;


    handleSubmit = (e) =>{
        e.preventDefault();

        const formData = new FormData(e.target),
        formDataObj = Object.fromEntries(formData.entries())

        this.setState({ resultVariant :'warning', resultVisible:false, resultText: '', spinnerHidden:false  });
        var PostData = {SystemID:"L", MyData : formDataObj};          
        
        fetch('/backend/mydata', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },
            body: JSON.stringify(PostData),
            }).then(response => response.json())
            .then(data => {
                if (data.errorsException===false) {
                    this.setState({ resultVariant :'success', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                } else {
                    this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
                }
            })
            .catch(error => {
    
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
    
        });
    }  


    componentDidMount() {
        this.getData();
    }

    getData = () =>{
    //getData() {
      fetch('/backend/mydata', {
        method: 'GET',
        headers: {
           'Content-Type': 'application/json',
           'accessToken':localStorage.getItem('access_token'),
        },
     }).then(response => response.json())
     .then(data => {
        if (data.errorsException===false) {
            
            this.setState({ resultVisible:false, spinnerHidden:false  });

            document.getElementById("formDataName").value = data.myData.name;
            document.getElementById("formDataEmail").value = data.myData.email;
            document.getElementById("formDataCompany").value = data.myData.company;
            document.getElementById("formDataOrgnr").value = data.myData.orgnr;

            document.getElementById("formDataStreet01").value = data.myData.street01;
            document.getElementById("formDataZip").value = data.myData.zip;
            document.getElementById("formDataCity").value = data.myData.city;

            this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true  });
        } else {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });

        }
     })
     .catch(error => {
        this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
        //this.setState({   });
      });
    }

    render() {
    return (
        <ContentWrapper>
            <Container>
                <Sida style={{minHeight:Pageheight(400)}}>
                <Rubrik>Avsluta konto</Rubrik>

    

                
            </Sida>
            </Container>
        </ContentWrapper>
    );
  }
}

export default MyComponent;