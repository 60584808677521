import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import SpinnerX from '../SpinnerX';
import {Pageheight} from "../../index";
import React from 'react';

class MyComponent extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          data: null,
          isLoading: false,
          error: null,
          resultVariant :"success",
          resultVisible:false,
          resultText :'N/A',
          spinnerHidden:false,
          subscriptions:null
        }
    }

    CheckAccessToken;

    componentDidMount() {
        this.getData();
    }

    getData = () =>{
        fetch('/backend/mysubscriptions', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessToken':localStorage.getItem('access_token'),
        },
        }).then(response => response.json())
        .then(data => {
            if (data.errorsException===false) {

                data.mySubscriptions.forEach(subscription => {
                    console.log(subscription.productName);
                    console.log(subscription.subscriptionComment);
                    console.log(subscription.subscriptionBindDate);
                    console.log(subscription.subscriptionState);
                });

                this.setState({ resultVariant :'success', resultVisible:false, resultText: data.resultText, spinnerHidden:true, subscriptions:data.mySubscriptions  });
            } else {
                console.log("2");
                this.setState({ resultVariant :'warning', resultVisible:true, resultText: data.resultText, spinnerHidden:true  });
            }
        })
        .catch(error => {
            this.setState({ resultVariant :'warning', resultVisible:true, resultText: 'Ett fel uppstod!', spinnerHidden:true  });
            //this.setState({   });
        });
    }    

    render() {
        if (this.state.subscriptions !== null) {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Mina Tjänster</Rubrik>
                        <StyckeCompact>
                            <Table  responsive="sm">
                                <thead>
                                    <tr>    <td>Produkt</td><td>Bindningstid</td><td>Pris/månad</td><td>Status</td>    </tr>
                                </thead>
                                <tbody>
                                    {this.state.subscriptions.map((item) => {
                                        return <tr><td>{item.productName}</td> <td>{item.subscriptionBindDate}</td> <td>{item.productPrice}:-</td> <td>{item.subscriptionState}</td></tr>
                                    })}
                                </tbody>
                            </Table>
                            <small><small>Priser angiva exklusive moms.</small></small>
                        </StyckeCompact>  

                        <Form onSubmit={this.handleSubmit}>

                            <Form.Group className="mb-3"  hidden={this.state.spinnerHidden}>
                                <SpinnerX  />
                            </Form.Group>                                      
                                
                            <Form.Group className="mb-3"  hidden={!this.state.resultVisible}>
                                <Alert show={this.state.resultVisible} variant={this.state.resultVariant}  className="FormSpace">{this.state.resultText}</Alert> 
                            </Form.Group>         
                        </Form>                 
                    </Sida>
                    </Container>
                </ContentWrapper>
            );
        } else {
            return (
                <ContentWrapper>
                    <Container>
                        <Sida style={{minHeight:Pageheight(400)}}>
                        <Rubrik>Laddar tjänster</Rubrik>
                        <SpinnerX  />
                    </Sida>
                    </Container>
                </ContentWrapper>
            );        
        }
    }
}

export default MyComponent;