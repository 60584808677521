import React, {useState} from "react";
import { Outlet, Link } from "react-router-dom";
import { Container, Button, Navbar, Nav,Form,NavDropdown} from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import './Menu.css';
import {XLogOff, Pageheight, CheckIfInlogged, XMenu, GetAccessToken} from "../index";

import {Sida,Rubrik,Stycke} from "./ContentStyles";

const history = createBrowserHistory();

const Menu = () => {

  const [testx, setTestx] = useState(true);

  const testfn = () => {

    if (window.scrollY > 120) {
      setTestx(false);
    } else {
      setTestx(true);
    }

  };

  window.addEventListener('scroll', testfn)
  

  return (

    <div className={testx ? 'NavbarBig' : 'NavbarSmall'}>

        <Navbar collapseOnSelect expand="lg" bg_="dark" variant="light">
          <Container>
            <Navbar.Brand href="/" >
                <img 
                alt=""
                src="/images/logo.png" 

                height="40px" 
                className="d-inline-block align-top"/>{' '}
                
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">

              <Nav className="me-auto" id="Test" > 
                <Nav.Link style={{color:"white"}} href="/">Hem</Nav.Link>
                
                <Nav.Link style={{color:"white"}} href="/contact">Kontakt</Nav.Link>
                {!CheckIfInlogged() ? ( 
                    <></>
                ) :(
                  <>
                  <Nav.Link style={{color:"white"}} href="/mypage">Min Profil</Nav.Link>
                  <Nav.Link style={{color:"white"}} href="/instance/invoices">Mina Fakturor</Nav.Link>
                  <Nav.Link style={{color:"white"}} href="/instance/subscriptions">Mina Tjänster</Nav.Link>
                  </>
                )}
              </Nav>


              {!CheckIfInlogged() ? ( 
                    <>
                    <Nav>
                    <Button className="CloudLoginButton" variant="primary" href="/login">Logga in</Button>                     
                    </Nav>
                    </>             
                ) : ( 
                    <>
                    <Nav>
                    <Button className="CloudLoginButton"  variant="success" onClick={XLogOff}>Logga ut</Button>&nbsp;
                      </Nav>                    
                    </>            
                )} 
                  

            </Navbar.Collapse>
          </Container>
        </Navbar>

      <Outlet />
    </div>
  )
};

export default Menu;