import ClipLoader from "react-spinners/PacmanLoader";
import React, { useState, Component } from 'react';

import '../App.css';

const SpinnerX = () => {


  let [color, setColor] = useState("#23B8CC");
  
    return (
    <div className="SpinnerDiv">
    <ClipLoader color={color}  size={30} />
    </div>
    )
  };
  
  export default SpinnerX;