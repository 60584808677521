import { Button} from 'react-bootstrap';

import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContentDiv } from "./pages/ContentStyles";

import Menu     from "./pages/Menu";
import Top      from "./pages/Top";
import Footer   from './pages/Footer';

import CookieConsent    from "./pages/CookieConcent"

import Home     from "./pages/Home";

import Signup                 from "./pages/Signup";

import Login                  from "./pages/common/Login";
import MyPage                 from "./pages/common/MyPage";
import Debug                  from "./pages/common/Debug";
import NoPage                 from "./pages/common/NoPage";
import Contact                from "./pages/common/Contact";
import Invoices               from "./pages/common/Invoices";
import Terminate              from "./pages/common/Terminate";

import Subscriptions          from "./pages/common/Subscriptions";
import InstanceVerify         from "./pages/common/InstanceVerify";
import ChangePassword         from "./pages/common/ChangePassword";
import RequestNewPassword     from "./pages/common/RequestNewPassword";
import RequestNewPasswordSet  from "./pages/common/RequestNewPasswordSet";

import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';

export   function XLogOff() {
  localStorage.setItem('access_token', "");
  localStorage.setItem('isLoggedIn', true);
  window.location.href = "/login";
}

export function Pageheight(offset) {
  //const [size, setSize] = useState({x: window.innerWidth,y: (window.innerHeight-500)});
  
 //return 900;
  
  // const updateSize = () => {
  //   setSize({x: window.innerWidth,y: (window.innerHeight-500)});
  // }

  return window.innerHeight-offset;

  // useEffect(() => (window.onresize = updateSize), []);  
  // useEffect(() => (window.onscroll = updateSize), []);
}

export function GetAccessToken() {
  var access_token = localStorage.getItem('access_token');

  return access_token;
}

export function CheckAccessToken() {
  var access_token = localStorage.getItem('access_token');

  if (access_token==="") {
    window.location.href = "/login";
    return false;
  } else {
    return true;
  }
}

export function CheckIfInlogged() {
  var access_token = localStorage.getItem('access_token');

  if (access_token==="") {
    return false;
  } else {
    return true;
  }
}

export function XMenu() {
  return (
    <div className="FormSpace">
      <Button variant="outline-success" href="/password/change">Byt lösenord</Button>&nbsp;
      <Button variant="outline-success" href="/subscriptions">Mina tjänster</Button>&nbsp;
      <Button variant="outline-success" href="/invoices">Mina fakturor</Button>&nbsp;
      <Button variant="outline-success" href="/mypage">Mina sidor</Button>&nbsp;
      <Button variant="outline-success" onClick={XLogOff}>Logga ut</Button>&nbsp;
    </div>
  );
}

export default function App() {
  return (
    <>
      <Menu />
      <Top />

      <ContentDiv>
        <BrowserRouter>
          <Routes>
            <Route index                                element={<Home />} />
            <Route path="signup"                        element={<Signup />} />

            <Route path="instance/Invoices"             element={<Invoices />} />
            <Route path="instance/terminate"            element={<Terminate />} />
            <Route path="instance/verify/:GUID"         element={<InstanceVerify />} />
            <Route path="instance/Subscriptions"        element={<Subscriptions />} /> 

            <Route path="Password/Request/new"         element={<RequestNewPassword />} />
            <Route path="Password/Request/set/:GUID"   element={<RequestNewPasswordSet />} />
            <Route path="Password/change"              element={<ChangePassword />} />

            <Route path="contact"  element={<Contact />} />           
            <Route path="login"    element={<Login />} />  
            <Route path="MyPage"   element={<MyPage />} />                
            <Route path="Debug"   element={<Debug />} />     
            <Route path="*"        element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </ContentDiv>
      <Footer />
      <CookieConsent />
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));