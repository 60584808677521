import '../App.css';

import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form} from 'react-bootstrap';

import $ from "jquery";
import Faq from "./Faq";
import React, { useState, Component } from 'react';
import axios from "axios";
import {Sida,Rubrik,Stycke,ContentWrapper} from "./ContentStyles";
import Prices from "./Prices";
import CountDown from "./CountDown";


const shoot = () => {
  alert();
}

const Home = () => {

   return(
        <ContentWrapper>
    
        <Container>
        <Sida>
          <Rubrik>Elbilsägare</Rubrik>
          <Stycke>Vi tror att du kommer att ladda din bil där du ändå befinner dig av någon anledning, tex när du lunchar, tränar osv. Vi jobbar därför med att utöka vår portfölj av molntjänster. 
          <br></br><b>Mer info kommer snart!</b>
          </Stycke>

          <Rubrik>Verksamhetsägare</Rubrik>
          <Stycke>
          ELiTanken kompletterar och gör din verksamhet till en EL-mack. Låt dina kunder ladda bilen utanför tex gymmet, restaurangen, butiken, fotbollsträningen, golfklubben, padelbanan, vandrarhemmet osv.
            <br></br><b>Mer info kommer snart!</b>
          </Stycke>


          <Rubrik>Snart kan du ladda ner vår app :)</Rubrik>
          <Stycke>
              <a   href="https://www.apple.com/se/app-store/"><img height='40px' src="https://www.qmenow.com/images/AppStore.png" /></a>&nbsp;
              <a   href="https://play.google.com/store/apps/"><img height='40px' src="https://www.qmenow.com/images/GooglePlay.png" /></a>&nbsp;

          </Stycke>
    
          <Rubrik>Frågor och svar!</Rubrik>
          <Faq />
          
        </Sida>    
        </Container>

        </ContentWrapper>
      );
   }

export default Home;